<template>
  <div class="form-elements">
    <div class="container">
      <vuestic-widget v-if="checklistIsEmpty">
        <div class="row justify--center">
          <h2 class="mt-4">Esta checklist não possui nenhuma resposta</h2>
        </div>
        <div class="row justify--end">
          <button
            class="btn btn-pale btn-micro"
            @click="$router.go(-1)"
          >
            Voltar
          </button>
        </div>
      </vuestic-widget>
      <vuestic-widget v-else>
        <div class="row justify--center">
          <h2 class="mt-1">{{checklist.nome}}</h2>
        </div>

        <collapse
          v-for="area in checklist.area_da_checklist"
          :data="area"
          label="nome"
          :key="area.id"
          :editar="false"
        >
          <vuestic-widget class="p-0">
            <data-tables
              class="style-body-table"
              layout="table"
              :data="area.pergunta"
              :table-props="tableProps"
            >
              <el-table-column
                prop="pergunta"
                label="NOME"
                sortable="custom"
                width="200"
              />
              <el-table-column
                prop="tipos_de_respostas"
                label="Resposta"
                sortable="custom"
              >
                <template slot-scope="row">
                  <all
                    :naoSeAplica="row.row.nao_se_aplica"
                    :tipo="row.row.tipos_de_respostas"
                    :resposta="getResposta(row.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="Anexo"
                prop="imagem"
              >
                <!--  width="70" -->
                <template
                  slot-scope="row"
                  style="width: 100%;"
                >
                  <i
                    v-if="getImage(row.row)"
                    class="el-icon-picture style-icon-img primary-color"
                    @click.stop="openImage(getImage(row.row))"
                  />
                </template>
              </el-table-column>
            </data-tables>
          </vuestic-widget>
        </collapse>
        <br />
      </vuestic-widget>
    </div>
    <modal-visualizar-imagem
      ref="MVI"
      :link="linkImage"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import collapse from '@/components/my-components/form/collapse.vue';
import all from '@/vuestic-theme/vuestic-components/vuestic-tipo_de_resposta/todasAsRespostas.vue';
import ModalVisualizarImagem from '../user-supervisor/form/modalVisualizarImagem.vue';

export default {
  name: 'cadastrar-checklist',
  components: {
    all,
    collapse,
    ModalVisualizarImagem,
  },
  data() {
    return {
      show: false,
      idChecklist: null,
      idResposta: null,
      checklistIsEmpty: false,
      tableProps: {
        stripe: true,
      },
      checklist: {},
      linkImage: '',
    };
  },
  created() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    this.$loading().close();
    next();
  },
  methods: {
    ...mapActions('checklist', [
      'getChecklistPorAcesso',
    ]),

    init() {
      this.idChecklist = this.$router.history.current.params.checklist;
      this.idResposta = this.$router.history.current.params.resposta;
      this.getChecklist();
    },
    getResposta(pergunta) {
      if (pergunta.pergunta_resposta !== null && pergunta.pergunta_resposta.length > 0) {
        return pergunta.pergunta_resposta[0];
      }
      return {};
    },
    getImage(pergunta) {
      if (pergunta.pergunta_resposta !== null && pergunta.pergunta_resposta.length > 0) {
        return pergunta.pergunta_resposta[0].imagem;
      }
      return false;
    },
    openImage(link) {
      const baseUrl = this.$axios.defaults.baseURL;
      this.linkImage = `${baseUrl}${link}`;
      this.$refs.MVI.open();
    },
    openFullScreenLoading() {
      const loading = this.$loading({
        lock: true,
        text: 'Carregando',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      return loading;
    },

    async getChecklist() {
      let loading;
      try {
        loading = this.openFullScreenLoading();
        const params = { minha: '', checklist: this.idChecklist, resposta: this.idResposta };
        const empresa = Number(localStorage.empresaSelecionada);
        const res = await this.getChecklistPorAcesso({ id: empresa, params });
        this.checklist = { ...res[0] };
        this.checklistIsEmpty = Object.keys(this.checklist).length === 0;
      } catch (error) {
        console.error(error);
      } finally {
        loading.close();
      }
    },
    push(name, params) {
      this.$router.push({
        name,
        params,
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },

  },

};
</script>

<style>
.style-body-table {
	border: 1px solid #c2c2c2;
}
.cell .container .row .icons {
	width: 30px !important;
	height: 30px !important;
	margin: 0% !important;
}
.color-white {
	background: white;
}
</style>
