import { render, staticRenderFns } from "./checklistRespostaDasPerguntas.vue?vue&type=template&id=8cbac9ac&"
import script from "./checklistRespostaDasPerguntas.vue?vue&type=script&lang=js&"
export * from "./checklistRespostaDasPerguntas.vue?vue&type=script&lang=js&"
import style0 from "./checklistRespostaDasPerguntas.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports